body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #ffffff; /* Change this to white or any other color */
  color: #333;
  height: 100%;
  overflow: hidden;
}

#root, html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}

header {
  background: #35495e;
  color: rgb(90, 83, 83);
  padding: 10px 0;
  text-align: center;
  flex: 0 0 auto;
}

main {
  flex: 1 1 auto;
  overflow: hidden;
}

.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
  padding: 20px;
}

.button {
  display: inline-block;
  background: #35495e;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease;
}

.button:hover {
  background: #3a6073;
}

.gm-style-iw button {
  display: none !important;
}
